<template>
  <div class="h-16 bg-bb-red-50 flex justify-between items-center px-4 rounded-2xl">
    <div class="flex justify-start items-center gap-3 h-full">
      <div class="element bg-bb-red-100 h-8 w-8 flex items-center justify-center">
        <ic-bell class="text-bb-red-error" />
      </div>
      <p class="text-sm font-bold">{{ newCount }} new potential infringements have been identified!</p>
    </div>

    <merge-button-round
      button-type="primary"
      class="w-30"
      @click="$router.push({ name: 'infringements-detector' })"
    >
      <p>View</p>
    </merge-button-round>
  </div>
</template>

<script>
import IcBell from '@/components/icon/ic-bell.vue'

export default {
  name: 'InfringementBanner',
  components: { IcBell },
  props: {
    newCount: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style scoped lang="scss">
.element {
  border-radius: 9999px;
  transition: border-radius 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.element:hover {
  border-radius: 12px;
}
</style>
