<template>
  <div class="w-full h-full bg-neutral-0 p-6 pb-20 space-y-6 relative">
    <div
      v-if="isOverviewLoading"
      class="flex h-full items-center justify-center absolute top-0 mx-auto w-full inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <brightbid-loader />
    </div>

    <infringement-banner
      v-if="infringementSummary?.newCount > 0 ?? false"
      :new-count="infringementSummary.newCount"
    />

    <dialog-box
      v-if="hasSavingsInsights"
      :savings-insights="savingsInsights"
    />

    <!--ROW 1-->
    <div class="flex flex-col lg:flex-row gap-6">
      <!--COMPETITOR INSIGHTS-->
      <competitor-insights :competitor-insights="computedCompetitorInsights" />

      <!--HOW YOU RANK-->
      <how-you-rank
        :your-competitors="yourCompetitors.slice(0, 2)"
        :how-you-rank="howYouRank.rank || 0"
        :is-paused-campaign="monitoringJob.pauseCampaign"
        @show-protect-brand-modal="showProtectBrandModal"
        @update-pause-campaign-flag="updatePauseCampaignFlag"
      />
    </div>

    <!--ROW 2-->
    <div class="flex gap-6 w-full h-100">
      <div class="w-2/5">
        <share-of-search :top-competitors="topCompetitorsData" />
      </div>
      <div class="w-3/5">
        <top-competitors
          v-if="topCompetitorsData.length > 0"
          :top-competitors="topCompetitorsData"
        />
      </div>
    </div>

    <!--ROW 3-->
    <div class="flex gap-6 w-full h-100">
      <div class="w-3/5">
        <MostFrequentRank
          v-if="monitoringJob.id"
          :monitoring-job="monitoringJob"
        />
      </div>
      <div class="w-2/5">
        <top-of-page-chart :competition-ranking="competitionRanking" />
      </div>
    </div>

    <!--MODAL-->
    <bb-base-modal
      v-if="modalConfig.modal"
      :width="modalConfig.width"
      :height="modalConfig.height"
      @close="closeModal"
    >
      <template #container>
        <protect-brand-modal
          @confirmed="updatePauseCampaignFlag"
          @close="closeModal"
        />
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
// Components
import TopOfPageChart from '@/views/site/search/competitor_monitoring/overview/cards/TopOfPageChart.vue'
import ProtectBrandModal from '@/components/modals/ProtectBrandModal.vue'
import TopCompetitors from '@/views/site/search/competitor_monitoring/overview/cards/TopCompetitors.vue'
import MostFrequentRank from '@/views/site/search/competitor_monitoring/overview/cards/MostFrequentRank.vue'
import DialogBox from '@/views/site/search/competitor_monitoring/overview/cards/DialogBox.vue'
import InfringementBanner from '@/views/site/search/competitor_monitoring/overview/cards/InfringementBanner.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import CompetitorInsights from '@/views/site/search/competitor_monitoring/overview/cards/CompetitorInsights.vue'
import HowYouRank from '@/views/site/search/competitor_monitoring/overview/cards/HowYouRank.vue'
import ShareOfSearch from '@/views/site/search/competitor_monitoring/overview/cards/ShareOfSearch.vue'

import { mapActions, mapState, mapGetters } from 'vuex'
import { getWebsiteFromURL } from '@/utils/url-util'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'

export default {
  name: 'CompetitorMonitoring',
  components: {
    BrightbidLoader,
    TopOfPageChart,
    MostFrequentRank,
    TopCompetitors,
    DialogBox,
    InfringementBanner,
    BbBaseModal,
    ProtectBrandModal,
    CompetitorInsights,
    HowYouRank,
    ShareOfSearch,
  },
  data() {
    return {
      modalConfig: { width: '', height: '', modal: null },
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('competitorMonitoring', [
      'competitorInsights',
      'monitoringJob',
      'competitionRanking',
      'topCompetitors',
      'savingsInsights',
      'isOverviewLoading',
      'infringementSummary',
    ]),
    ...mapGetters({ isMonitoringJobStopped: 'competitorMonitoring/isMonitoringJobStopped' }),
    hasSavingsInsights() {
      return (
        this.savingsInsights?.numberOfPauses > 0 ||
        this.savingsInsights?.totalTimeSavedSeconds > 0 ||
        this.savingsInsights?.totalMoneySaved?.value > 0
      )
    },
    computedCompetitorInsights() {
      return [
        {
          title: 'Competitors',
          value: this.competitorInsights?.totalCompetitors,
          icon: 'IcNormalPeople',
          iconColor: 'text-bb-brand-purple',
          iconBgColor: 'bg-bb-purple-50',
        },
        {
          title: 'New Entrants',
          value: `${this.competitorInsights?.entered > 0 ? '+' : ''}${this.competitorInsights?.entered}`,
          icon: 'IcPositivePeople',
          iconColor: 'text-bb-red-error',
          iconBgColor: 'bg-bb-red-50',
          iconClass: 'ml-2',
        },
        {
          title: 'Exits',
          value: `${this.competitorInsights?.left > 0 ? '-' : ''}${this.competitorInsights?.left}`,
          description: 'the auction the last 30 days',
          icon: 'IcNegativePeople',
          iconColor: 'text-bb-blue',
          iconBgColor: 'bg-bb-light-blue',
          iconClass: 'ml-2',
        },
      ]
    },
    howYouRank() {
      if (this.isOverviewLoading || this.competitionRanking.length === 0 || !this.competitionRanking) return []
      return this.competitionRanking.find(item => item.isOwn)
    },
    yourCompetitors() {
      const competitors = this.competitionRanking.filter(item => !item.isOwn)
      return competitors.map(item => {
        return {
          name: item.competitorName ?? getWebsiteFromURL(item.competitor),
          rank: item.rank,
        }
      })
    },
    topCompetitorsData() {
      const clonedTopCompetitors = structuredClone(this.topCompetitors)
      return clonedTopCompetitors.map(item => {
        return {
          competitor: item.competitorName ?? getWebsiteFromURL(item.competitor),
          url: item.competitor,
          ranks: item.ranks,
          marketSharePercentage: item.marketSharePercentage,
          isOwn: item.isOwn,
        }
      })
    },
  },
  methods: {
    ...mapActions('competitorMonitoring', ['setOverviewLoading', 'loadMonitoringJob']),
    ...mapActions('toast', ['loadToast']),
    showProtectBrandModal() {
      this.modalConfig = { width: '720px', height: '500px', modal: 'protect-your-brand' }
    },
    closeModal() {
      this.modalConfig = { width: '', height: '', modal: null }
    },
    async updatePauseCampaignFlag(pauseCampaign) {
      if (this.isMonitoringJobStopped) {
        await this.loadToast({
          title: 'Warning',
          message: "You're not allowed to enable the Smart Activation while the monitoring job is paused.",
          type: 'warning',
        })
        return
      }

      try {
        this.setOverviewLoading(true)
        this.closeModal()
        await this.$http.patch(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.monitoringJob.id}/pause-campaign`,
          {
            pauseCampaign,
          },
        )
        const clonedMonitoringJob = structuredClone(this.monitoringJob)
        clonedMonitoringJob.pauseCampaign = pauseCampaign
        this.loadMonitoringJob(clonedMonitoringJob)
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to enable the smart activation. Please try again later.',
          type: 'error',
        })
      } finally {
        this.setOverviewLoading(false)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
