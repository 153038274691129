<template>
  <div class="text-bb-text-default px-5 py-6 bg-white w-full space-y-3 rounded-lg shadow-md">
    <div class="flex items-center h-full w-full">
      <div class="flex justify-evenly w-full">
        <div class="space-y-3 w-full pl-3">
          <p class="text-sm font-medium">How you rank</p>
          <div class="flex items-center justify-center w-11 h-11 bg-purple-0 rounded-full">
            <p class="text-2xl font-bold text-bb-brand-purple">{{ howYouRank }}</p>
          </div>
          <p class="font-normal">In paid results</p>
        </div>
        <div class="space-y-3 w-full">
          <p class="text-sm font-medium">Top Competitors</p>
          <div v-if="yourCompetitors.length === 0">No competitors found</div>
          <div
            v-for="competitor in yourCompetitors.slice(0, 2)"
            v-else
            :key="competitor.name"
            class="w-fit text-bb-fuchsia-pink bg-bb-blush-pink text-xs font-bold py-1 px-2"
            style="border-radius: 4px"
          >
            <p>{{ competitor.name }}</p>
          </div>
        </div>
        <div class="h-full my-auto w-full text-center">
          <p class="text-sm font-medium">Protect your budget</p>
          <p class="text-center text-bb-neutral-gray text-xs">
            Automatically pause your campaign <br />
            when competition is low
          </p>
          <div class="pt-4">
            <MergeButtonRound
              v-if="!isPausedCampaign"
              button-type="brand-purple"
              @click="showProtectBrandModal"
            >
              <div class="flex items-center gap-2 justify-center mx-auto">
                <div>
                  <ic-magic-wand />
                </div>
                <p class="w-40">Enable Smart Activation</p>
              </div>
            </MergeButtonRound>
          </div>
          <div>
            <MergeButtonRound
              v-if="isPausedCampaign"
              button-type="secondary"
              @click="updatePauseCampaignFlag(false)"
            >
              <div class="flex items-center gap-2 justify-center mx-auto">
                <div>
                  <ic-stop />
                </div>
                <p class="w-37">Stop Smart Activation</p>
              </div>
            </MergeButtonRound>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcStop from '@/components/icon/ic-stop.vue'
import IcMagicWand from '@/components/icon/brightbid/ic-magic-wand.vue'

export default {
  name: 'HowYouRank',
  components: { IcMagicWand, IcStop },
  props: {
    howYouRank: {
      type: Number,
      required: true,
    },
    yourCompetitors: {
      type: Array,
      required: true,
    },
    isPausedCampaign: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updatePauseCampaignFlag(value) {
      this.$emit('update-pause-campaign-flag', value)
    },
    showProtectBrandModal() {
      this.$emit('show-protect-brand-modal')
    },
  },
}
</script>

<style scoped lang="scss"></style>
