<template>
  <div class="w-full h-full flex place-content-center">
    <div class="text-center my-auto">
      <slot name="image">
        <img
          class="h-40 mx-auto"
          src="/content/images/brightbid/bidbot-no-data.svg"
        />
      </slot>
      <slot name="title">
        <p class="font-medium">No data available yet.</p>
      </slot>
      <slot name="message">
        <p class="text-sm">Please try a different date range or check back later.</p>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoChartData',
}
</script>

<style scoped lang="scss"></style>
