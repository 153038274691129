<template>
  <div class="text-bb-text-default px-5 py-6 bg-white w-full space-y-3 rounded-lg shadow-md">
    <h4 class="text-sm font-medium text-bb-text-default">Competitors Insights</h4>
    <div class="flex justify-evenly w-full p-4">
      <div
        v-for="(insight, index) in competitorInsights"
        :key="index"
        class="flex gap-3 items-center"
        :class="insight.title === 'Exits' ? 'w-auto' : 'w-full'"
      >
        <div>
          <div
            class="rounded-full h-50px w-50px flex items-center justify-center"
            :class="insight.iconBgColor"
          >
            <component
              :is="insight.icon"
              :class="[insight.iconColor, insight.iconClass]"
            />
          </div>
        </div>
        <div class="space-y-1">
          <h2 class="text-3xl font-bold">{{ insight.value }}</h2>
          <p class="text-sm text-bb-neutral-gray">{{ insight.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcNormalPeople from '@/components/icon/brightbid/ic-normal-people.vue'
import IcPositivePeople from '@/components/icon/brightbid/ic-positive-people.vue'
import IcNegativePeople from '@/components/icon/brightbid/ic-negative-people.vue'
import IcDonutChart from '@/components/icon/brightbid/ic-donut-chart.vue'
import IcInfo from '@/components/icon/ic-info.vue'

export default {
  name: 'CompetitorInsights',
  components: { IcNormalPeople, IcPositivePeople, IcNegativePeople, IcDonutChart, IcInfo },
  props: {
    competitorInsights: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss"></style>
