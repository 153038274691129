import { render, staticRenderFns } from "./CompetitorInsights.vue?vue&type=template&id=6bf03eed&scoped=true"
import script from "./CompetitorInsights.vue?vue&type=script&lang=js"
export * from "./CompetitorInsights.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bf03eed",
  null
  
)

export default component.exports