<template>
  <div class="text-bb-text-default px-6 py-4 bg-white rounded-lg shadow-md w-full h-100">
    <div>
      <div class="flex justify-between items-center">
        <div class="flex">
          <div class="flex gap-2 items-center">
            <div>
              <IcCharts />
            </div>
            <div class="text-gray-700 text-base font-medium my-auto pl-2">Most frequent rank</div>
            <new-tooltip direction="bottom-start">
              <ic-info class="text-bb-disabled-buttons" />
              <template #content>
                <div class="w-70">
                  <p>
                    See the most frequent rank over time per advertiser. Use the dropdown to switch between advertisers
                    bidding on your keywords.
                  </p>
                </div>
              </template>
            </new-tooltip>
          </div>
        </div>
        <div @click="resetChart">
          <ic-reset
            v-if="hiddenLegend.length > 0"
            class="text-bb-brand-purple cursor-pointer"
          />
        </div>
      </div>
      <div v-if="noChartData">
        <no-chart-data class="py-10" />
      </div>
      <div
        v-else
        class="mb-4 mt-3"
      >
        <div class="flex text-xs gap-5 justify-center flex-wrap">
          <div
            v-for="(competitor, index) in competitors"
            :key="index"
            class="flex items-center gap-2 cursor-pointer"
            @click="toggleLegend(index)"
          >
            <div
              class="h-2 w-2 rounded-full"
              :style="`background: ${colors[index]}`"
            />
            <p
              class="hover:font-bold duration-300 ease-linear"
              :class="hiddenLegend.includes(index) ? 'text-bb-disabled-gray' : 'text-bb-text-default'"
            >
              {{ competitor.label }}
            </p>
          </div>
        </div>
        <LineChart
          ref="lineChart"
          style="height: 290px"
          :chart-data="chartData"
          :options="options"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewTooltip from '@/components/alert/NewTooltip.vue'
import IcInfo from '@/components/icon/ic-info.vue'
import IcCharts from '@/components/icon/ic-charts.vue'
import LineChart from '@/components/chart/base/LineChart.vue'
import IcReset from '@/components/icon/ic-reset.vue'
import NoChartData from '@/views/site/search/competitor_monitoring/overview/cards/NoChartData.vue'
import { mapState } from 'vuex'
import dayjs from 'dayjs'

const colors = ['#6366FA', '#84FAE4', '#A3CDFE', '#FFA776', '#FFDB76']
const backgroundColors = ['#d5d5fd', '#d6fff5', '#d4e0f5', '#f89362', '#fae6b5']

export default {
  name: 'MostFrequentRank',
  components: {
    IcCharts,
    LineChart,
    NewTooltip,
    IcInfo,
    IcReset,
    NoChartData,
  },
  data() {
    return {
      numberOfRanks: 10,
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                color: 'rgba(0,0,0,0.5)', // Change opacity to 50%
              },
              gridLines: {
                color: 'rgba(0,0,0,0)', // Lighter grid lines
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                color: 'rgba(0,0,0,0)', // Fully transparent ticks
                reverse: true, // Invert the y-axis
                callback: function (value) {
                  // Force tick labels to be integers
                  return Number.isInteger(value) ? value : ''
                },
                max: this.numberOfRanks,
              },
              gridLines: {
                color: 'rgba(0,0,0,0.04)', // Very light grid lines
                borderDash: [5, 5], // Sets dashed grid lines: 5px dash, 5px gap
                // drawOnChartArea: false,
              },
            },
          ],
        },
        elements: {
          line: {
            backgroundColor: 'rgba(99, 102, 251, 0.2)', // Background color under the line chart
          },
        },
      },
      competitors: [],
      timestamps: [],
      hiddenLegend: [],
      competitorsData: {},
      MostFrequentRankSelectBox: 'MostFrequentRankSelectBox',
      selectedCompetitor: null,
      colors,
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('competitorMonitoring', ['frequencyRanks', 'dateRange', 'frequencyRanksDataPoints']),
    noChartData() {
      return Object.keys(this.frequencyRanks).length === 0 || !this.frequencyRanks
    },
  },
  watch: {
    frequencyRanks: {
      deep: true,
      immediate: true,
      handler() {
        this.competitorsData = structuredClone(this.frequencyRanks)
        this.timestamps = Object.keys(this.frequencyRanks).sort((a, b) => new Date(a) - new Date(b))
        this.competitors = Object.values(this.frequencyRanks)
          .reduce((acc, item) => {
            item.forEach(i => {
              if (!acc.some(competitor => competitor.value === i.competitorName)) {
                acc.push({
                  value: i.competitorName,
                  label: i.competitorName,
                })
              }
            })
            return acc
          }, [])
          .slice(0, 5)

        if (this.noChartData) return
        this.initializeChart()
      },
    },
  },
  methods: {
    initializeChart() {
      const datasets = this.competitors.map((i, index) => {
        let rawData = Object.values(this.competitorsData)
          .flat()
          .filter(item => item.competitorName === i.label)
        return {
          label: this.competitors[index].label,
          data: rawData.map(item => item.rank),
          fill: 'start',
          borderColor: colors[index],
          borderWidth: 2,
          backgroundColor: ctx => {
            const canvas = ctx.chart.ctx
            var gradient = canvas.createLinearGradient(0, 0, 0, 400)
            gradient.addColorStop(0, colors[index])
            gradient.addColorStop(0.8, backgroundColors[index] + '00')
            return gradient
          },
        }
      })

      this.chartData = {
        labels: this.timestamps.map(item => dayjs(item).format('DD/MM HH:mm')), // x-axis
        datasets: datasets,
      }
    },
    toggleLegend(i) {
      this.hiddenLegend = this.hiddenLegend.includes(i)
        ? this.hiddenLegend.filter(item => item !== i)
        : [...this.hiddenLegend, i]

      const chartInstance = this.$refs.lineChart.$data._chart
      const meta = chartInstance.getDatasetMeta(i)
      meta.hidden = !meta.hidden
      chartInstance.update()
    },
    resetChart() {
      this.hiddenLegend.map(i => {
        this.toggleLegend(i)
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
