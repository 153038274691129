import * as d3 from 'd3'

export const createDonutSvg = ({ chartId, data, sizes }) => {
  let durations = {
    entryAnimation: 2000,
    labelFadeIn: 300,
  }

  data = data.filter(item => item.percentage > 0)

  let loading = true // Start with loading as true

  const addLabels = chart => {
    const arc = d3
      .arc()
      .innerRadius(sizes.innerRadius * 1.5)
      .outerRadius(sizes.outerRadius)

    const labelGroup = d3
      .select(`#${chartId}`)
      .append('g')
      .attr('transform', `translate(${sizes.width / 2},${sizes.height / 2})`)
      .selectAll('g')
      .data(chart)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${arc.centroid(d)})`)
      .attr('class', `label-group-${chartId}`) // Add class to the label group for easier selection

    labelGroup
      .append('circle')
      .attr('id', d => `circle-${data[d.index].label}-${chartId}`) // Add unique ID for each arc
      .attr('r', 16)
      .attr('fill', 'white')
      .attr('stroke', '#E0E0E0')
      .attr('stroke-width', 1)
      .style('opacity', 0) // Initially hidden

    labelGroup
      .append('text')
      .attr('id', d => `label-${data[d.index].label}-${chartId}`) // Add unique ID for each arc
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .attr('stroke', '#080808')
      .attr('stroke-width', 0.2)
      .style('fill', '#080808')
      .style('font-size', '12px')
      .style('font-weight', 500)
      .text(d => `${data[d.index].percentage}%`)
      .style('opacity', 0) // Initially hidden
  }

  d3.select(`#${chartId}`).html('')

  const generator = d3.pie().sort(null)
  const chart = generator(data.map(d => d.value))

  const arc = d3.arc().innerRadius(sizes.innerRadius).outerRadius(sizes.outerRadius)

  const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('visibility', 'hidden')
    .style('background', '#fff')
    .style('border', '1px solid #ccc')
    .style('padding', '5px')
    .style('border-radius', '4px')
    .style('box-shadow', '0 0 10px rgba(0,0,0,0.1)')
    .style('font-size', '12px')

  const arcs = d3
    .select(`#${chartId}`)
    .append('g')
    .attr('transform', `translate(${sizes.width / 2},${sizes.height / 2})`)
    .attr('style', 'max-width: 100%; height: auto;')
    .selectAll('path')
    .data(chart)
    .enter()
    .append('path')
    .style('fill', d => data[d.index].color)
    .attr('stroke', 'white')
    .attr('stroke-width', 5)
    .attr('id', d => `arc-${data[d.index].label}-${chartId}`) // Add unique ID for each arc
    .on('mouseover', (event, d) => {
      if (loading) return
      d3.select(`#arc-${data[d.index].label}-${chartId}`)
        .transition()
        .duration(100)
        .attr('transform', () => `scale(1.05)`)

      tooltip.style('visibility', 'visible').text(data[d.index].label)

      // Show the corresponding label
      d3.select(`#circle-${data[d.index].label}-${chartId}`)
        .transition()
        .duration(durations.labelFadeIn)
        .style('opacity', 1) // Show label
      d3.select(`#label-${data[d.index].label}-${chartId}`)
        .transition()
        .duration(durations.labelFadeIn)
        .style('opacity', 1) // Show label
    })
    .on('mousemove', event => {
      if (loading) return
      tooltip.style('top', event.pageY - 10 + 'px').style('left', event.pageX + 10 + 'px')
    })
    .on('mouseout', (event, d) => {
      if (loading) return
      // Check if loading is false
      d3.select(`#arc-${data[d.index].label}-${chartId}`)
        .transition()
        .duration(100)
        .attr('transform', () => `scale(1)`)

      tooltip.style('visibility', 'hidden')

      // Hide the corresponding label
      d3.selectAll(`.label-group-${chartId}`)
        .filter((_, i) => i === d.index)
        .selectAll('circle, text')
        .transition()
        .duration(durations.labelFadeIn)
        .style('opacity', 0) // Hide label
    })

  const angleInterpolation = d3.interpolate(generator.startAngle()(), generator.endAngle()())

  arcs
    .transition()
    .duration(durations.entryAnimation)
    .attrTween('d', d => {
      let originalEnd = d.endAngle
      return t => {
        let currentAngle = angleInterpolation(t)
        if (currentAngle < d.startAngle) {
          return ''
        }

        d.endAngle = Math.min(currentAngle, originalEnd)

        return arc(d)
      }
    })
    .on('end', (d, i, nodes) => {
      if (i === nodes.length - 1) {
        addLabels(chart)
        loading = false // Set loading to false when animation ends
      }
    })

  d3.select(`#${chartId}`)
    .attr('width', sizes.width)
    .attr('height', sizes.width)
    .attr('style', 'max-width: 100%; height: auto;')
    .attr('viewBox', [0, 0, sizes.height, sizes.width])
    .transition()
    .duration(durations.entryAnimation)
    .tween('arcRadii', () => {
      return () => arc.innerRadius(sizes.innerRadius).outerRadius(sizes.outerRadius).cornerRadius(5)
    })
}
